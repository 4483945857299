import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Transaction from "./page/Transaction";
import { Role } from "./constants/role.ts";
import LoginContainer from "./page/Login";
import useUser from "./hooks/user/useUser.ts";

function App() {
  const user = useUser();

  if (user && [Role.Integration].includes(user?.role))
    return (
      <Router>
        {/* <Header /> */}

        <Routes>
          <Route path="/" element={<Transaction />} />
          {/* <Route path="/users" element={<Users />} /> */}
          {/* <Route path="/skins" element={<Skins />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/skins-on-bots" element={<SkinsOnBots />} />
          <Route path="/bots" element={<Bots />} /> */}
        </Routes>
      </Router>
    );
  else return <LoginContainer />;
}

export default App;
