import React from "react";
import { useFormik } from "formik";
import "./Login.css";
import useLogin from "../../../hooks/auth/useLogin.ts";

const Login = () => {
  // Initialize the login hook
  const loginFetch = useLogin(); 

  // Initialize Formik
  const formik = useFormik({
    initialValues: {
      apiKey: "",
    },
    validate: (values) => {
      const errors = {};
      if (!values.apiKey) {
        errors.apiKey = "API Key is required";
      }
      return errors;
    },
    onSubmit: (values) => {
      loginFetch.refetch({ token: values.apiKey });
    },
  });

  return (
    <div className="login-container">
      <form onSubmit={formik.handleSubmit} className="login-form">
        <div className="form-group">
          <label htmlFor="apiKey">API Key</label>
          <input
            id="apiKey"
            name="apiKey"
            type="text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.apiKey}
            className={formik.touched.apiKey && formik.errors.apiKey ? "input-error" : ""}
            placeholder="Enter your API Key"
          />
          {formik.touched.apiKey && formik.errors.apiKey ? (
            <div className="error">{formik.errors.apiKey}</div>
          ) : null}
        </div>

        <button type="submit" className="login-button">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
