import { useState, useCallback } from "react";
import { BACKEND_URL } from "../../assets/constants.js";
import { apiRequest } from "../../util/apiRequest.ts";
import { toast } from "react-toastify";

interface LoginDto {
  username: string;
  password: string;
}

const setCookie = (name: string, value: string, days: number) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${encodeURIComponent(value)};expires=${expires.toUTCString()};path=/`;
};

const useLogin = (initialQuery: { page: number }) => {
  const [query, setQuery] = useState(initialQuery);
  const [data, setData] = useState<LoginDto | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(async (queryParams = query) => {
    setLoading(true);
    setError(null);
    try {
      const responseData = await apiRequest<LoginDto>(
        `/api/auth/login/token`,
        {
          method: "GET",
          queryParams, // Use the provided queryParams
        }
      );
      console.log(responseData);
      setData(responseData);

      // Set the cookie with responseData
      if (responseData) {
        setCookie('access_token', responseData as unknown as string, 7);
      }

      toast.success('Авторизован');
    } catch (err) {
      setError(err as Error);
      console.log(err);
      toast.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  }, [query]);

  const refetch = useCallback((newQuery: { page: number }) => {
    setQuery(newQuery);
    fetchData(newQuery);
  }, [fetchData]);

  return { data, loading, error, refetch };
};

export default useLogin;
