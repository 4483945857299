import React, { useEffect, useState } from "react";
import "./TransactionBlock.css";
import useFetch from "../../../hooks/useFetch.ts";
import Pagination from "./Pagination.js";
import { formatDate } from "../../../util/formatDate.ts";
import { TradeStatus } from "../../../constants/tradeStatus.ts";
import useWithMethodFetch from "../../../hooks/useWithMethodFetch.ts";
import { StatusTransaction } from "../../../constants/statusTransaction.ts";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import Modal from 'react-modal';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,  // Шкала для оси X
  LinearScale,    // Шкала для оси Y
  PointElement,
  LineController,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { statusPayment } from "../../../constants/statusPayment.ts";


ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  Title,
  Tooltip,
  Legend
);




const TransactionBlock = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chartData, setChartData] = useState({});
  const [modalTitle, setModalTitle] = useState("");


  const [pageNumber, setPageNumber] = useState(1);
  const [transaction, setTransaction] = useState([]);
  const [searchTerms, setSearchTerms] = useState({
    SteamIdUser: "",
    SteamIdBot: "",
    offerId: "",
    status: "",
    type: "",
    dateStart: "",
    dateEnd: "",
  });


  const updateTransaction = useWithMethodFetch(`/api/transaction`);
  const createTransactionPaymenting = useWithMethodFetch(`/api/payment/user`);


  const dataStats = useFetch("/api/transaction/integration/stats", {
    method: "GET",
    queryParams: { detail: true },
  });

  const dataTransaction = useFetch("/api/transaction/all", {
    method: "GET",
    queryParams: { page: pageNumber },
  });

  const updateStatusTransaction = useWithMethodFetch("/api/transaction/status-accept", {
    method: "PATCH",
  });

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected + 1)

    dataTransaction.refetch("/api/transaction/all", {
      method: "GET",
      queryParams: { page: selected + 1, ...searchTerms },
    });
  };

  const openModalWithChart = (statusData) => {
    setIsModalOpen(true);
    setModalTitle(statusData.status);

    // Преобразование массива дат в Date объекты для дальнейшей обработки

    if(!statusData?.totalCostDays) return []

    const allDates = statusData.totalCostDays.map(day => new Date(day.date));

    // Находим минимальную и максимальную дату
    const minDate = new Date(Math.min(...allDates));
    const maxDate = new Date(Math.max(...allDates));

    // Функция для создания диапазона всех дат между минимальной и максимальной
    const getDateRange = (startDate, endDate) => {
      const dateArray = [];
      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        dateArray.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1); // Увеличиваем на 1 день
      }

      return dateArray;
    };

    // Генерируем диапазон всех дат между minDate и maxDate
    const dateRange = getDateRange(minDate, maxDate);

    // Создаем объект для быстрого поиска данных по дате
    const costDataByDate = statusData.totalCostDays.reduce((acc, day) => {
      acc[new Date(day.date).toLocaleDateString()] = day.totalcost;
      return acc;
    }, {});

    // Заполняем данные для графика, если для даты нет значения - ставим 0
    const dates = dateRange.map(date => date.toLocaleDateString());
    const costs = dateRange.map(date => costDataByDate[date.toLocaleDateString()] || 0);

    setChartData({
      labels: dates,
      datasets: [
        {
          label: `Total cost (${statusData.status})`,
          data: costs,
          fill: false,
          backgroundColor: 'rgba(75,192,192,0.4)',
          borderColor: 'rgba(75,192,192,1)',
        },
      ],
    });
  };



  const handleSearchChange = (field) => (e) => {
    const newSearchTerms = {
      ...searchTerms,
      [field]: e.target.value,
    };
    setSearchTerms(newSearchTerms);

    dataStats.refetch("/api/transaction/integration/stats", {
      method: "GET",
      queryParams: { page: pageNumber, type: newSearchTerms.type },
    });

    dataTransaction.refetch("/api/transaction/all", {
      method: "GET",
      queryParams: { page: pageNumber, ...newSearchTerms },
    });
  };

  const changeStatusSuccessTransaction = async () => {
    await updateStatusTransaction.request({
      method: "PATCH",
      body: {
        id0: document.getElementById('id0_changeStatus').value,
        id1: document.getElementById('id1_changeStatus').value,
      }
    });

    dataTransaction.refetch("/api/transaction/all", {
      method: "GET",
      queryParams: { page: pageNumber, ...searchTerms },
    });
  }

  const createTransactionPaymentingFunc = async (idTrans) => {
    if (!window.confirm('Точно выплатить?')) return

    document.getElementById(`trans_${idTrans}_button_paymeting`).disabled = true

    await createTransactionPaymenting.request({
      method: "POST",
      body: {
        idTrans: idTrans
      }
    });

    dataTransaction.refetch("/api/transaction/all", {
      method: "GET",
      queryParams: { page: pageNumber, ...searchTerms },
    });
  }

  const changeStatus = async (transaction, newStatus) => {
    if (newStatus == StatusTransaction.Cancel && !window.confirm(`Если отменить транзакцию, то будет обновлено стоимост принятых предметов на боте! Уверены что хотите отменить?`)) return

    await updateTransaction.request({
      method: "PATCH",
      body: {
        idTrans: transaction.id,
        newStatus
      }
    });

    dataTransaction.refetch("/api/transaction/all", {
      method: "GET",
      queryParams: { page: pageNumber, ...searchTerms },
    });
  }

  useEffect(() => {
    if (dataTransaction.data)
      setTransaction(dataTransaction.data.data)
  }, [dataTransaction.data])

  return (
    <div className="transaction-container">
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Chart Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2>{modalTitle}</h2>
        <Line data={chartData} />
        <button onClick={() => setIsModalOpen(false)}>Close</button>
      </Modal>


      <div className="transaction-container_filter">
        <input
          value={searchTerms.SteamIdUser}
          onChange={handleSearchChange("SteamIdUser")}
          placeholder="SteamId User"
        />
        <input
          value={searchTerms.SteamIdBot}
          onChange={handleSearchChange("SteamIdBot")}
          placeholder="SteamId Bot"
        />
        <input
          value={searchTerms.offerId}
          onChange={handleSearchChange("offerId")}
          placeholder="Offer Id"
        />
        <select
          value={searchTerms.status}
          onChange={handleSearchChange("status")}
        >
          <option value="">Все</option>
          <option value="create">Новые</option>
          <option value="paymenting">Выплата</option>
          <option value="success">Успешные</option>
          <option value="cancel">Отмененные</option>
        </select>


        {/* Date Filters */}
        <input
          type="date"
          value={searchTerms.dateStart}
          onChange={handleSearchChange("dateStart")}
          placeholder="Start Date"
        />
        <input
          type="date"
          value={searchTerms.dateEnd}
          onChange={handleSearchChange("dateEnd")}
          placeholder="End Date"
        />
      </div>

      <div className="transaction-container_stats">
        <div>
          {dataStats.data && dataStats.data.map(stat => {
            return (<div>
              <h3>{stat.type}</h3>
              <div className="transaction-container_stats-blocks">
                {stat.stats.map(statCost => {
                  return <div onClick={() => openModalWithChart(statCost)} className={`transaction-container_transactions-status ${statCost.status} transaction-container_stats-blocks-block`}>
                    <span >{statCost.status}</span>
                    <span className="transaction-container_stats-blocks-block-cost">{statCost.totalcost.toFixed(2)} $</span>
                  </div>
                })}
              </div>

            </div>)
          })}
        </div>


        
      </div>

      <div className="transaction-container_transactions">
        {transaction.map((transaction, i) => {
          const titlePayment = transaction.paymentType.id == -1 ? 'Интеграция' : transaction.paymentType.title
          return (<div key={transaction.id} className="transaction-container_transactions-item">

            <div className="transaction-container_transactions-id">
              <span >#{transaction.id}</span>
              {transaction.metaData?.walletNumber &&
                <>

                  <CopyToClipboard
                    text={transaction.metaData?.walletNumber}
                    onCopy={() => toast.success("Скопировано " + transaction.metaData?.walletNumber)}
                  >
                    <span className="transaction-container_transactions-wallet">Кошелек: {transaction.metaData?.walletNumber}</span>
                  </CopyToClipboard>
                  {transaction.status == StatusTransaction.Paymenting && !transaction.payment.filter(v => ![statusPayment.Error].includes(v.status)).length
                    ? <button 
                     className="transaction-container_transactions-wallet-button_paymeting"
                    id={`trans_${transaction.id}_button_paymeting`}
                     onClick={() => createTransactionPaymentingFunc(transaction.id)}>Выплатить</button>
                    : ''}
                </>


              }

            </div>

            {transaction.metaData?.fullName || transaction.metaData?.telegramContact || transaction.metaData?.email ? <div className="transaction-container_transactions-infoUserWallet">
              <CopyToClipboard
                text={transaction.metaData?.fullName}
                onCopy={() => toast.success("Скопировано")}
              >
                <span>ФИО: {transaction.metaData?.fullName}</span>
              </CopyToClipboard>
              <CopyToClipboard
                text={transaction.metaData?.telegramContact}
                onCopy={() => toast.success("Скопировано")}
              >
                <span>Tg: {transaction.metaData?.telegramContact}</span>
              </CopyToClipboard>
              <CopyToClipboard
                text={transaction.metaData?.email}
                onCopy={() => toast.success("Скопировано")}
              >
                <span>Email: {transaction.metaData?.email}</span>
              </CopyToClipboard>
            </div> : ''}



            <span className={`transaction-container_transactions-status ${transaction.status}`}>{transaction.status}</span>
           

            <div className="transaction-container_transactions-date">
              <span>Создан: {formatDate(transaction.createdAt)}</span>
              <span>Обновлено: {formatDate(transaction.updatedAt)}</span>
            </div>

            <div onClick={() => window.open(`https://steamcommunity.com/profiles/${transaction.user.SteamID}`)} className="transaction-container_transactions-infoUser">
              <span >{transaction.user.SteamID}</span>

            </div>

            <span className={`transaction-container_transactions-cost ${transaction.status}`}>{transaction.cost} $</span>

            <a className="transaction-container_transactions-idTrade" target="_blank"
              href={`https://steamcommunity.com/tradeoffer/${transaction.trade.offerId}`}>#{transaction.trade.offerId} ({TradeStatus[`_${transaction.trade.status}`]}) на бота {transaction.trade.bot_steam.SteamID}</a>

            <div className="transaction-container_transactions-trade">

              {transaction.trade.skin_transaction.map(item => {
                const img =
                  item.icon_url && item.icon_url.includes("http")
                    ? item.icon_url
                    : `https://community.akamai.steamstatic.com/economy/image/${item.icon_url}`;

                const buffMin = item.buff_min
                const tmAvg30 = item.tm_avg
                // const tmAvg7 = checkPriceTime(item['csgotm_avg7'])

                const minPrice = Math.min(...[tmAvg30].filter(v => v))

                const profitPercent = +(-(1 - minPrice * 0.91 / item.priceOnTransaction) * 100).toFixed(0)
                return <ContextMenuTrigger id={`contextmenu-${item.assetId}`}>
                  <span className="transaction-container_transactions-price">{item.priceOnTransaction} $</span>

                  <CopyToClipboard
                    text={item.hash_name}
                    onCopy={() => toast.success("Скопировано")}
                  >

                    <img src={img} title={item.hash_name} />

                  </CopyToClipboard>

                  <ContextMenu id={`contextmenu-${item.assetId}`}>
                    <MenuItem
                      onClick={() =>
                        window.open(
                          `https://steamcommunity.com/market/listings/${item.appid}/${item.hash_name}`,
                          "_blank"
                        )
                      }
                    >
                      Открыть в Steam Market
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        switch (item.appid) {
                          case "730":
                            return window.open(
                              `https://market.csgo.com/en/1/1/${item.hash_name}`,
                              "_blank"
                            )

                          case "570":
                            return window.open(
                              `https://market.dota2.net/?search=${item.hash_name}&sd=asc&s=price`,
                              "_blank"
                            )

                          case "440":
                            return window.open(
                              `https://tf2.tm/?search=${item.hash_name}&sd=asc&s=price`,
                              "_blank"
                            )

                          case "252490":
                            return window.open(
                              `https://rust.tm/?search=${item.hash_name}&sd=asc&s=price`,
                              "_blank"
                            )
                          default:
                            break;
                        }
                      }
                      }
                    >
                      Открыть на тм
                    </MenuItem>
                  </ContextMenu>
                </ContextMenuTrigger>
              })}


            </div>


          </div>)
        })}

      </div>

      <Pagination
        pageCount={dataTransaction.data?.count / 30}
        handlePageClick={handlePageClick}
        pageIndex={pageNumber - 1}
      />
    </div>
  );
};

export default TransactionBlock;
